<template>
  <validation-observer ref="simpleRules">
    <validation-provider
      #default="{ errors }"
      :rules="required ? 'required' : ''"
    >
      <b-form-group
        :state="errors.length > 0 ? false : null"
        :label="getNomeLabel"
        label-for="Select-Categorias"
        class="m-0"
      >
        <v-select
          id="Select-Categorias"
          v-model="CategoriasSelecionadas"
          @input="SelecionaCategorias"
          multiple
          variant="custom"
          item-text="titulo"
          item-value="id_categoria"
          label="titulo"
          :options="Categorias"
          multiselect
          :placeholder="placeholderProp"
          :disabled="Disabled"
        />
        <small v-if="errors.length > 0" class="text-danger">Campo Obrigatório</small>
      </b-form-group>
    </validation-provider>
  </validation-observer>
</template>

<script>
import { ValidationProvider, ValidationObserver } from "vee-validate";
import vSelect from "vue-select";
import { BCol, BFormGroup } from "bootstrap-vue";

export default {
  components: {
    ValidationProvider,
    ValidationObserver,
    vSelect,
    BCol,
    BFormGroup,
  },
  props: {
    required: {
      type: Boolean,
      default: false,
    },
    values: {
      type: Array,
      default: [],
    },
    disabledProp: {
      type: Boolean,
      default: false,
    },
    placeholderProp: {
      type: String,
      default: 'Categorias',
    },
    nomeLabel: {
      type: String,
      default: 'Categorias',
    },
  },

  data() {
    return {
      CategoriasSelecionadas: [],
      Categorias: [],
      Disabled: false,
      rota: this.$api.AjudaCategoria,
    };
  },
  methods: {
    SelecionaCategorias() {
      this.$emit("GetCategorias", this.CategoriasSelecionadas);
    },
    buscaCategorias() {
      this.$http.get(this.rota).then((res) => {
        this.Categorias = res.data.data;
      });

    },
  },

  mounted() {
    this.buscaCategorias();
  },

  computed: {
    getNomeLabel() {
      if (this.required) {
        let nomeLabel = this.nomeLabel;
        return `${nomeLabel} *`;
      }

      return this.nomeLabel;
    }
  },

  watch: {
    values(value) {

      this.CategoriasSelecionadas = value;
    },
    disabledProp(value) {
      this.Disabled = value;
    },
  },
};
</script>
