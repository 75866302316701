<template>
    <div>
        <div v-if="loading" class="w-100 mt-5 mb-5 d-flex flex-column align-items-center justify-content-center">
            <b-spinner variant="custom" class="mb-1" label="Loading..."></b-spinner>
            <span>Processando...</span>
        </div>
        <b-form v-if="!loading">
            <b-row>
                <b-col cols="12" sm="12" lg="12" class="mb-2">
                    <b-form-group label="Tópico *" label-for="topico">
                        <b-form-input
                            id="nome"
                            v-model="getForm.topico"
                            autocomplete="off"
                            placeholder="Ex: Como fazer uma determinada ação?"
                            readonly="readonly"
                        />
                    </b-form-group>
                </b-col>

                <b-col cols="12" sm="12" lg="12" class="mb-2">
                    <b-form-group label="Conteúdo *" label-for="conteudo">
                        <b-form-textarea
                            id="conteudo"
                            rows="6"
                            v-model="getForm.conteudo"
                            autocomplete="off"
                            placeholder="Ex: Você faz uma determinada ação clicando aqui e depois ali."
                            readonly="readonly"
                        />
                    </b-form-group>
                </b-col>

                <b-col cols="12" sm="12" lg="12" class="mb-2">
                    <label class="m-0">Categorias *</label>
                    <div class="border rounded bg-light" style="padding: 5px">
                        <span v-for="(categoria,index) in getForm.categorias" class="badge badge-primary mr-1">{{ categoria.titulo }}</span>
                    </div>
                </b-col>
            </b-row>

            <b-row>
                <b-col cols="12" sm="12" class="text-center">
                    <button
                        @click="handleCancel"
                        type="button"
                        class="btn btn-outline-cancel"
                    >
                        Fechar
                    </button>
                </b-col>
            </b-row>
        </b-form>
    </div>
</template>

<script>
import FieldCategorias from '@/views/components/custom/ajuda/CategoriasSelect.vue';
import vSelect from "vue-select";
import {validation} from "@core/mixins/validation/validation";
import {messages} from "@core/mixins/validation/messages";

import {
    BForm,
    BRow,
    BCol,
    BButton,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BSpinner,
    BFormTextarea
} from 'bootstrap-vue';

export default {
    components: {
        FieldCategorias,
        BForm,
        BRow,
        BCol,
        BButton,
        BFormGroup,
        BFormInput,
        BFormSelect,
        BSpinner,
        BFormTextarea,
        vSelect,
    },

    placeholderCategorias: 'Aguarde, buscando registros...',

    props: [
        'form',
        'categorias',
        'placeholderCategorias',
        'updateAction',
    ],

    mixins: [validation, messages],

    mounted() {
        this.setCurrentData();
    },

    data() {
        return {
            errors: {},
            currentCategorias: [],
            loading: false,
        }
    },

    methods: {
        handleFormData(formData) {
            return {
                topico: formData.topico,
                conteudo: formData.conteudo,
                categorias: formData.categorias,
            };
        },

        handleClear() {
            this.errors = {};
        },

        handleCancel() {
            this.handleClear();
            this.$emit('cancel');
        },

        core() {
            this.updateAction ? this.handleUpdate() : this.handleInsert();
        },

        setCurrentData() {
            this.currentCategorias = this.getForm.categorias;
        },
    },

    computed: {
        getForm() {
            return this.form;
        }
    }
}
</script>

<style scoped>
.btn-insert {
    background: linear-gradient(45deg, #7030A0 0%, #A369CE 100%);
    color: #fff;
    transition: .3s background-color;
}

.btn-insert:hover {
    background: linear-gradient(45deg, #60248d 0%, #9950d0 100%);
    color: #fff;
}

.btn-outline-cancel {
    background-color: transparent;
    border: solid 0.094rem #82868B;
    color: #82868B;
    transition: .3s;
}

.btn-outline-cancel:hover {
    text-decoration: underline;
}

@media(max-width: 576px) {
    .btn-insert, .btn-outline-cancel  {
        width: 100%;
        margin-bottom: 15px;
    }
}
</style>
