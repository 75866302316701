import { render, staticRenderFns } from "./DetalheTopico.vue?vue&type=template&id=e181b1dc&scoped=true&"
import script from "./DetalheTopico.vue?vue&type=script&lang=js&"
export * from "./DetalheTopico.vue?vue&type=script&lang=js&"
import style0 from "./DetalheTopico.vue?vue&type=style&index=0&id=e181b1dc&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "e181b1dc",
  null
  
)

export default component.exports